export const icoHotels = ['512 512', `
<title>Hotels</title>
<g>
	<g>
		<path d="M145.046,230.404H85.321c-4.712,0-8.532,3.82-8.532,8.532v85.321c0,4.712,3.82,8.532,8.532,8.532h59.725
			c4.712,0,8.532-3.82,8.532-8.532v-85.321C153.578,234.224,149.758,230.404,145.046,230.404z M136.514,315.725H93.853v-68.257
			h42.661V315.725z"/>
	</g>
</g>
<g>
	<g>
		<path d="M290.091,230.404h-59.725c-4.712,0-8.532,3.82-8.532,8.532v85.321c0,4.712,3.82,8.532,8.532,8.532h59.725
			c4.712,0,8.532-3.82,8.532-8.532v-85.321C298.623,234.224,294.803,230.404,290.091,230.404z M281.559,315.725h-42.66v-68.257
			h42.66V315.725z"/>
	</g>
</g>
<g>
	<g>
		<path d="M290.091,366.917h-59.725c-4.712,0-8.532,3.82-8.532,8.532v85.321c0,4.712,3.82,8.532,8.532,8.532h59.725
			c4.712,0,8.532-3.82,8.532-8.532v-85.321C298.623,370.738,294.803,366.917,290.091,366.917z M281.559,452.238h-42.66v-68.257
			h42.66V452.238z"/>
	</g>
</g>
<g>
	<g>
		<path d="M426.605,230.404H366.88c-4.712,0-8.532,3.82-8.532,8.532v85.321c0,4.712,3.82,8.532,8.532,8.532h59.725
			c4.712,0,8.532-3.82,8.532-8.532v-85.321C435.137,234.224,431.317,230.404,426.605,230.404z M418.073,315.725h-42.66v-68.257
			h42.66V315.725z"/>
	</g>
</g>
<g>
	<g>
		<path d="M145.046,366.917H85.321c-4.712,0-8.532,3.82-8.532,8.532v85.321c0,4.712,3.82,8.532,8.532,8.532h59.725
			c4.712,0,8.532-3.82,8.532-8.532v-85.321C153.578,370.738,149.758,366.917,145.046,366.917z M136.514,452.238H93.853v-68.257
			h42.661V452.238z"/>
	</g>
</g>
<g>
	<g>
		<path d="M426.605,366.917H366.88c-4.712,0-8.532,3.82-8.532,8.532v85.321c0,4.712,3.82,8.532,8.532,8.532h59.725
			c4.712,0,8.532-3.82,8.532-8.532v-85.321C435.137,370.738,431.317,366.917,426.605,366.917z M418.073,452.238h-42.66v-68.257
			h42.66V452.238z"/>
	</g>
</g>
<g>
	<g>
		<path d="M477.798,494.899V204.807h8.532c4.712,0,8.532-3.82,8.532-8.532v-42.66c0-4.712-3.82-8.532-8.532-8.532h-8.532v-34.128
			c0-4.712-3.82-8.532-8.532-8.532h-17.064V8.569c0-4.712-3.82-8.532-8.532-8.532H68.257c-4.712,0-8.532,3.82-8.532,8.532v93.853
			H42.661c-4.712,0-8.532,3.82-8.532,8.532v34.128h-8.532c-4.712,0-8.532,3.82-8.532,8.532v42.66c0,4.712,3.82,8.532,8.532,8.532
			h8.532v290.091H0v17.064h42.661h426.605H512v-17.064H477.798z M76.789,17.101h358.348v85.321H76.789V17.101z M51.193,119.486
			h17.064h375.412h17.064v25.596H51.193V119.486z M460.733,494.899H51.193V204.807h409.541V494.899z M42.661,187.743h-8.532v-25.596
			h8.532h426.605h8.532v25.596h-8.532H42.661z"/>
	</g>
</g>
<g>
	<g>
		<path d="M242.858,46.292c-1.357-3.468-3.263-6.463-5.721-8.984c-2.457-2.521-5.414-4.499-8.869-5.932
			c-3.456-1.433-7.282-2.15-11.48-2.15c-4.198,0-8.031,0.71-11.499,2.131c-3.468,1.421-6.437,3.398-8.907,5.932
			c-2.47,2.534-4.383,5.535-5.74,9.004c-1.357,3.468-2.035,7.237-2.035,11.307c0,4.07,0.678,7.839,2.035,11.307
			c1.357,3.468,3.27,6.469,5.74,9.004c2.47,2.534,5.439,4.511,8.907,5.932c3.468,1.421,7.301,2.131,11.499,2.131
			c4.198,0,8.024-0.71,11.48-2.131c3.456-1.42,6.412-3.398,8.869-5.932c2.457-2.534,4.364-5.535,5.721-9.004
			c1.357-3.468,2.035-7.237,2.035-11.307C244.893,53.529,244.214,49.76,242.858,46.292z M233.086,65.796
			c-0.806,2.419-1.965,4.467-3.475,6.143c-1.51,1.677-3.347,2.963-5.51,3.859c-2.163,0.896-4.601,1.344-7.314,1.344
			c-2.713,0-5.158-0.448-7.333-1.344c-2.176-0.896-4.025-2.182-5.548-3.859c-1.523-1.677-2.694-3.724-3.513-6.143
			c-0.819-2.419-1.229-5.151-1.229-8.197s0.41-5.778,1.229-8.197c0.819-2.419,1.99-4.473,3.513-6.162
			c1.523-1.689,3.372-2.982,5.548-3.878c2.176-0.896,4.62-1.344,7.333-1.344c2.713,0,5.151,0.448,7.314,1.344
			c2.163,0.896,3.999,2.189,5.51,3.878c1.51,1.689,2.668,3.743,3.475,6.162c0.806,2.419,1.209,5.151,1.209,8.197
			S233.893,63.377,233.086,65.796z"/>
	</g>
</g>
<g>
	<g>
		<polygon points="170.023,29.84 170.023,53.721 143.992,53.721 143.992,29.84 133.587,29.84 133.587,85.358 143.992,85.358 
			143.992,61.093 170.023,61.093 170.023,85.358 180.428,85.358 180.428,29.84 		"/>
	</g>
</g>
<g>
	<g>
		<polygon points="350.017,76.834 350.017,29.84 339.688,29.84 339.688,85.358 372.247,85.358 372.247,76.834 		"/>
	</g>
</g>
<g>
	<g>
		<polygon points="245.353,29.84 245.353,38.325 262.132,38.325 262.132,85.358 272.46,85.358 272.46,38.325 289.161,38.325 
			289.161,29.84 		"/>
	</g>
</g>
<g>
	<g>
		<polygon points="330.743,38.056 330.743,29.84 295.727,29.84 295.727,85.358 330.743,85.358 330.743,77.103 306.132,77.103 
			306.132,61.4 325.521,61.4 325.521,53.452 306.132,53.452 306.132,38.056 		"/>
	</g>
</g>
`]
