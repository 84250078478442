import React, { useState, Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Redirect } from 'react-router';
import moment from 'moment';
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
// Pages
const Signup = React.lazy(() => import('./public/signup/Signup'));
const Login = React.lazy(() => import('./public/login/Login'));
const Forgot = React.lazy(() => import('./public/forgot/Forgot'));
const Page404 = React.lazy(() => import('./public/page404/Page404'));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { auth: null };

    var auth = null;
    try {
      auth = localStorage.getItem('auth');
      if(auth) auth = JSON.parse(auth);
    } catch (exception) {}
    if(!auth || !auth.user || !auth.tokens || !auth.tokens.access || !auth.tokens.access.token || moment()>moment(auth.tokens.access.expires)) {
      //logout
      auth = null;
      this.authenticate(null); 
    }else{
      //logged in
      this.state = { auth: auth };
    }

    this.authenticate = this.authenticate.bind(this);
  }

  //login/logout
  authenticate(auth) {
    var redirect = null;
    if (this.state.auth && this.state.auth.user && this.state.auth.user.company && this.state.auth.user.company.category && this.state.auth.user.company.category === 'Closed Group' && this.state.auth.user.company.subdomain) redirect = this.state.auth.user.company.subdomain;
    this.setState({ auth: auth });
    localStorage.setItem('auth', JSON.stringify(auth));
    if (redirect) {
      document.location = 'https://myexclusiverewards.com/' + redirect;
      return false;
    }
    if(auth) return true;
    return false;
  }

  render() {
    return (
      <HashRouter>
          <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/signup" name="Signup" render={props => <Signup {...props}/>} />
            <Route exact path="/signup/:subdomain" name="Signup" render={props => <Signup {...props}/>} />
            <Route exact path="/login" name="Login" render={props => <Login {...props}/>} />
            <Route exact path="/login/:subdomain" name="Login" render={props => <Login {...props}/>} />
            <Route exact path="/forgot" name="Forgot" render={props => <Forgot {...props}/>} />
            <Route exact path="/forgot/:subdomain" name="Forgot" render={props => <Forgot {...props}/>} />
            <Route exact path="/404" name="404" render={props => <Page404 {...props}/>} />
            <Route exact path="/logout" name="Logout" render={() => (
              this.authenticate(null) ? 
                (<Redirect to="/login" />) :
                (<Redirect to="/login" />)
            )} />
            <Route path="/" name="Home" render={props => (
              this.state.auth ? 
                (<TheLayout authenticate={this.authenticate} auth={this.state.auth} {...props}/>) : 
                (<Redirect to="/login" />)
            )} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;




