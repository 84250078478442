export const icoCustomers = ['512 512', `
<title>Customers</title>
<g>
	<g>
		<path d="M315.046,361.686h-28.067c-4.142,0-7.5,3.358-7.5,7.5c0,4.143,3.358,7.5,7.5,7.5h28.067c4.142,0,7.5-3.357,7.5-7.5
			C322.546,365.044,319.188,361.686,315.046,361.686z"/>
	</g>
</g>
<g>
	<g>
		<path d="M480.248,281.394l-40.841-15.753l-20.823-20.823c-1.337-1.337-3.06-2.061-4.82-2.174v-16.569
			c9.814-10.464,16.8-23.715,19.619-38.643c1.75,0.079,3.517-0.062,5.26-0.418c9.979-2.043,17.222-11.302,17.222-22.014v-5.469
			c0-6.197-2.513-11.885-6.605-15.952c6.623-36.02,1.168-65.374-15.846-85.021c-16.457-19.003-42.971-28.086-76.672-26.263
			c-31.136,1.683-51.236,16.009-57.604,21.25c-3.486,0.414-9.424,1.638-15.434,5.244c-8.33,4.998-18.259,15.497-18.259,37.13
			c0,18.872,3.016,34.628,5.2,43.567c-7.32,3.566-12.217,11.294-12.217,20.129v5.384c0,10.712,7.243,19.971,17.222,22.014
			c1.743,0.357,3.511,0.497,5.26,0.418c2.819,14.928,9.806,28.179,19.619,38.643v16.569c-1.76,0.113-3.484,0.837-4.82,2.174
			l-20.823,20.823l-40.841,15.753c-3.533,1.362-6.908,3.129-10.03,5.25c-3.426,2.328-4.316,6.992-1.989,10.418
			c1.451,2.136,3.81,3.286,6.21,3.286c1.451,0,2.917-0.421,4.208-1.297c2.177-1.479,4.532-2.711,6.999-3.663l37.903-14.62
			l28.98,43.471c3.592,5.389,9.35,8.846,15.795,9.484c0.717,0.071,1.434,0.106,2.146,0.106c3.731,0,7.351-0.981,10.555-2.794
			l-6.219,141.068c-0.183,4.138,3.024,7.641,7.163,7.823c0.112,0.005,0.224,0.007,0.336,0.007c3.99,0,7.31-3.145,7.487-7.17
			l5.772-130.941h15.57l5.772,130.941c0.177,4.026,3.497,7.17,7.487,7.17c0.112,0,0.224-0.002,0.336-0.007
			c4.138-0.182,7.345-3.685,7.163-7.823l-6.219-141.068c3.204,1.813,6.823,2.794,10.555,2.794c0.712,0,1.429-0.035,2.146-0.106
			c6.445-0.638,12.202-4.095,15.795-9.484l28.98-43.471l37.903,14.62c5.316,2.05,9.892,5.321,13.49,9.402l-25.931,25.931
			c-9.369,9.369-14.528,21.825-14.528,35.073v106.64c0,4.142,3.358,7.5,7.5,7.5c4.142,0,7.5-3.358,7.5-7.5v-106.64
			c0-9.241,3.599-17.931,10.134-24.466l22.758-22.758c0.914,3.353,1.341,6.878,1.197,10.482l-5.809,143.078
			c-0.168,4.139,3.051,7.63,7.189,7.799c0.104,0.004,0.207,0.006,0.31,0.006c4.001,0,7.325-3.161,7.488-7.195l5.811-143.082
			C512.809,308.433,500.066,289.038,480.248,281.394z M295.592,184.339c-1.323-7.201-7.991-12.435-15.188-11.902
			c-0.569,0.041-1.155,0.001-1.745-0.119c-2.982-0.61-5.231-3.757-5.231-7.318v-5.386c0-3.479,2.325-6.613,5.292-7.134
			c2.504-0.44,4.902,0.479,6.419,2.458c1.377,1.795,3.214,3.079,5.311,3.711c3.072,0.928,6.459,0.383,9.063-1.455
			c4.69-3.311,8.828-7.356,12.299-12.024c2.472-3.324,1.78-8.022-1.543-10.493c-3.325-2.472-8.022-1.781-10.494,1.543
			c-1.731,2.328-3.687,4.441-5.84,6.311c-2.378-2.034-5.156-3.504-8.133-4.343c-1.927-7.382-5.357-23.197-5.357-42.271
			c0-11.487,3.541-19.558,10.524-23.988c5.449-3.457,11.216-3.595,11.212-3.596c1.917,0,3.783-0.755,5.175-2.072
			c0.183-0.172,18.62-17.282,50.194-18.989c28.902-1.56,51.214,5.736,64.523,21.104c13.414,15.49,17.881,39.271,13.035,69.036
			c-2.009-0.071-4.208,0.081-6.397,0.446l-7.046-16.922c-1.438-3.452-5.204-5.316-8.822-4.373
			c-0.697,0.182-69.958,17.74-98.999-18.416c-2.593-3.229-7.313-3.745-10.544-1.151c-3.229,2.594-3.745,7.314-1.15,10.544
			c29.656,36.922,88.5,28.89,108.088,24.973l5.449,13.085c2.343,5.625,8.337,8.697,14.245,7.304
			c2.425-0.571,4.742-0.584,5.612-0.412c3.033,0.604,5.32,3.629,5.32,7.039v5.47c0,3.562-2.249,6.708-5.231,7.318
			c-0.589,0.121-1.178,0.161-1.745,0.119c-7.196-0.524-13.864,4.7-15.188,11.903c-5.456,29.704-31.343,51.264-61.554,51.264
			C326.935,235.603,301.048,214.043,295.592,184.339z M328.863,316.915c-1.902,1.902-4.113,1.995-5.263,1.881
			c-1.149-0.113-3.3-0.639-4.792-2.877l-29.195-43.792l11.741-11.741l44.82,39.218L328.863,316.915z M350.022,326.822l0.454-10.308
			l6.669-6.669l6.669,6.669l0.454,10.308H350.022z M357.146,289.272l-41.617-36.415v-14.354c12.1,7.695,26.416,12.1,41.617,12.1
			s29.518-4.405,41.618-12.1v14.354L357.146,289.272z M395.486,315.919c-1.493,2.239-3.644,2.764-4.792,2.877
			c-1.151,0.114-3.362,0.021-5.264-1.882l-17.311-17.311l44.82-39.218l11.741,11.741L395.486,315.919z"/>
	</g>
</g>
<g>
	<g>
		<path d="M237.845,320.699l-36.622-15.985l-20.019-20.019c-1.341-1.342-3.065-2.062-4.82-2.174v-11.799
			c17.109-11.243,29.629-29.124,33.652-50.431c1.97,0.088,3.959-0.101,5.919-0.564c9.597-2.271,16.562-11.468,16.562-21.868v-5.468
			c0-7.598-3.77-14.433-9.611-18.443c1.718-6.806,2.594-13.769,2.594-20.778c0-31.072-16.796-58.44-42.113-74.154
			c-0.27-25.933-22.405-46.953-49.588-46.953c-27.183,0-49.318,21.02-49.588,46.953C58.895,94.73,42.099,122.099,42.099,153.17
			c0,6.988,0.87,13.931,2.578,20.718c-5.838,3.99-9.595,10.857-9.595,18.585v5.386c0,10.4,6.965,19.597,16.562,21.868
			c1.96,0.464,3.948,0.652,5.919,0.564c4.023,21.307,16.544,39.188,33.653,50.431v11.799c-1.755,0.112-3.479,0.832-4.82,2.174
			l-20.019,20.019l-36.621,15.985c-18.633,8.134-30.573,27.246-29.712,47.559l4.429,104.493c0.171,4.03,3.492,7.183,7.488,7.183
			c0.107,0,0.215-0.002,0.323-0.007c4.139-0.175,7.351-3.672,7.176-7.811l-4.43-104.494c-0.285-6.733,1.465-13.266,4.755-18.889
			l19.685,23.622c6.221,7.465,9.646,16.927,9.646,26.643v73.436c0,4.142,3.358,7.5,7.5,7.5c4.142,0,7.5-3.358,7.5-7.5v-73.436
			c0-13.218-4.661-26.09-13.123-36.245L30.066,337.64c1.779-1.226,3.67-2.312,5.691-3.194l31.916-13.932l22.554,36.609
			c2.227,3.615,5.909,6.066,10.103,6.726c0.754,0.119,1.51,0.177,2.263,0.177c3.429,0,6.769-1.215,9.417-3.475l14.292-12.195
			v124.077c0,4.142,3.358,7.5,7.5,7.5s7.5-3.358,7.5-7.5V348.358l14.291,12.195c2.648,2.26,5.988,3.475,9.417,3.475
			c0.752,0,1.509-0.059,2.263-0.177c4.194-0.66,7.876-3.111,10.103-6.726l22.554-36.61l31.916,13.932
			c2.02,0.882,3.912,1.968,5.691,3.194l-20.927,25.113c-8.462,10.154-13.123,23.027-13.123,36.245v73.436c0,4.142,3.358,7.5,7.5,7.5
			c4.142,0,7.5-3.358,7.5-7.5v-73.436c0-9.716,3.426-19.178,9.646-26.643l19.685-23.622c3.29,5.623,5.04,12.156,4.755,18.889
			l-4.429,104.494c-0.175,4.138,3.037,7.635,7.176,7.811c0.108,0.005,0.215,0.007,0.323,0.007c3.996,0,7.317-3.152,7.488-7.183
			l4.429-104.493C268.42,347.946,256.479,328.833,237.845,320.699z M133.801,47.063c15.956,0,29.418,10.179,33.399,23.971
			c-10.354-3.908-21.62-6.057-33.399-6.057c-11.779,0-23.045,2.149-33.399,6.057C104.383,57.242,117.844,47.063,133.801,47.063z
			 M72.246,217.2c-1.322-7.203-8.001-12.435-15.187-11.903c-0.637,0.045-1.295-0.009-1.959-0.167
			c-2.86-0.677-5.017-3.803-5.017-7.271v-5.386c0-3.479,2.325-6.613,5.292-7.134c2.504-0.44,4.901,0.48,6.417,2.457
			c1.377,1.796,3.214,3.08,5.313,3.714c3.071,0.928,6.46,0.383,9.062-1.456c4.619-3.26,8.703-7.234,12.139-11.81
			c2.487-3.312,1.818-8.014-1.494-10.5c-3.313-2.487-8.014-1.818-10.501,1.494c-1.701,2.265-3.616,4.324-5.722,6.153
			c-3.209-2.744-7.144-4.462-11.318-4.983c-1.437-5.649-2.172-11.426-2.172-17.237c0-40.358,34.408-73.193,76.702-73.193
			c42.293,0,76.701,32.834,76.701,73.193c0,5.8-0.731,11.565-2.163,17.204c-1.974,0.157-3.98,0.48-5.837,0.962l-5.638-4.636
			c-2.833-2.33-6.399-3.496-10.008-3.281c-11.024,0.651-67.482,2.142-96.448-32.524c-2.656-3.178-7.385-3.602-10.564-0.946
			c-3.179,2.656-3.602,7.386-0.946,10.564c14.286,17.097,35.239,28.658,62.276,34.362c20.701,4.367,39.103,3.943,46.313,3.532
			l8.796,7.233c2.421,1.991,5.816,2.272,8.527,0.694c1.272-0.693,5.532-1.355,7.391-0.984c3.033,0.603,5.319,3.629,5.319,7.04v5.468
			c0,3.469-2.157,6.595-5.017,7.271c-0.663,0.157-1.322,0.214-1.959,0.166c-7.182-0.522-13.865,4.701-15.188,11.903
			c-5.456,29.705-31.343,51.265-61.554,51.265C103.59,268.464,77.702,246.904,72.246,217.2z M102.702,348.776L80.144,312.16
			L91.7,300.604l31.058,31.058L102.702,348.776z M133.801,321.491l-27.584-27.584v-15.516c8.611,3.278,17.916,5.073,27.584,5.073
			c9.668,0,18.973-1.795,27.584-5.072v15.515L133.801,321.491z M164.9,348.776l-20.056-17.114l31.058-31.058l11.556,11.556
			L164.9,348.776z"/>
	</g>
</g>
<g>
	<g>
		<path d="M105.734,394.766h-21.05c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h21.05c4.142,0,7.5-3.358,7.5-7.5
			C113.234,398.124,109.876,394.766,105.734,394.766z"/>
	</g>
</g>
`]
