import { icoHome } from './home'
import { icoDashboard } from './dashboard'

import { icoVouchers } from './vouchers'
import { icoEvents } from './events'
import { icoTickets } from './tickets'
import { icoCustomers } from './customers'

import { icoRules } from './rules'
import { icoScenarios } from './scenarios'
import { icoWaivers } from './waivers'

import { icoOffers } from './offers'
import { icoTemplates } from './templates'
import { icoCampaigns } from './campaigns'
import { icoReports } from './reports'

import { icoCompany } from './company'
import { icoUsers } from './users'
import { icoProfile } from './profile'
import { icoLogout } from './logout'

import { icoCompanies } from './companies'

import { icoHotels } from './hotels'
import { icoCruises } from './cruises'
import { icoActivities } from './activities'

import { icoWallet } from './wallet'
import { icoCards } from './cards'

import { icoBook } from './book'

import { icoBookings } from './bookings'
import { icoCommissions } from './commissions'
import { icoCashbacks } from './cashbacks'

import { icoAlerts } from './alerts'
import { icoWebhooks } from './webhooks'

import {
  cilUser,
  cilLockLocked,
  cilArrowLeft,
  cilMediaPlay,
  cilSettings,
  cilX,
  cilPlus,
  cilBuilding,
  cilGroup,
  cilAccountLogout,
  cilArrowThickFromBottom,
  cilCheckAlt,
  cilTags,
  cilMoney,
  cilArrowRight,
  cilLevelDown,
  cilLevelUp,
  cilCode,
  cilAppsSettings,
  cilCloudUpload,
  cilCloudDownload,
  cilLoopCircular,
  cilFork,
  cilSend,
  cilCreditCard,
} from '@coreui/icons'

export const icons = Object.assign({}, {
  icoHome,
  icoDashboard,
  icoVouchers,
  icoEvents,
  icoTickets,
  icoCustomers,

  icoRules,
  icoScenarios,
  icoWaivers,

  icoOffers,
  icoTemplates,
  icoCampaigns,
  icoReports,

  icoCompany,
  icoUsers,
  icoProfile,
  icoLogout,

  icoCompanies,

  icoHotels,
  icoCruises,
  icoActivities,

  icoWallet,
  icoCards,

  icoBook,

  icoBookings,
  icoCommissions,
  icoCashbacks,

  icoAlerts,
  icoWebhooks,
  
}, {
  cilUser,
  cilLockLocked,
  cilArrowLeft,
  cilMediaPlay,
  cilSettings,
  cilX,
  cilPlus,
  cilBuilding,
  cilGroup,
  cilAccountLogout,
  cilArrowThickFromBottom,
  cilCheckAlt,
  cilTags,
  cilMoney,
  cilArrowRight,
  cilLevelDown,
  cilLevelUp,
  cilCode,
  cilAppsSettings,
  cilCloudUpload,
  cilCloudDownload,
  cilLoopCircular,
  cilFork,
  cilSend,
  cilCreditCard,
})
