export const icoWallet = ['512 512', `
<title>Wallet</title>
<g>
	<g>
		<path d="M325.866,83.594V53.72c7.094,2.298,12.087,7.697,12.087,13.977c0,4.214,3.415,7.63,7.63,7.63s7.63-3.416,7.63-7.63
			c0-14.493-11.719-26.643-27.346-29.681v-2.224c0-4.214-3.415-7.63-7.63-7.63c-4.215,0-7.63,3.416-7.63,7.63v2.224
			c-15.627,3.037-27.346,15.187-27.346,29.68s11.719,26.643,27.346,29.68v29.872c-7.094-2.296-12.087-7.696-12.087-13.975
			c0-4.214-3.415-7.63-7.63-7.63c-4.215,0-7.63,3.416-7.63,7.63c0,14.493,11.719,26.643,27.346,29.68v2.224
			c0,4.214,3.415,7.63,7.63,7.63c4.215,0,7.63-3.416,7.63-7.63v-2.224c15.627-3.037,27.346-15.187,27.346-29.68
			C353.212,98.781,341.493,86.63,325.866,83.594z M310.607,81.673c-7.094-2.297-12.087-7.696-12.087-13.976
			c0-6.28,4.993-11.679,12.087-13.977V81.673z M325.865,127.25V99.297c7.094,2.298,12.087,7.697,12.087,13.976
			C337.952,119.553,332.959,124.952,325.865,127.25z"/>
	</g>
</g>
<g>
	<g>
		<path d="M318.236,0c-49.893,0-90.485,40.591-90.485,90.486c-0.001,4.214,3.415,7.63,7.629,7.63c4.215,0,7.63-3.416,7.63-7.63
			c0-41.479,33.746-75.227,75.226-75.227s75.227,33.746,75.227,75.227c0,41.48-33.747,75.226-75.227,75.226
			c-2.986,0-5.989-0.176-8.926-0.523c-4.178-0.491-7.978,2.497-8.472,6.681c-0.494,4.184,2.497,7.977,6.681,8.472
			c3.53,0.417,7.135,0.629,10.716,0.629c49.894,0,90.486-40.591,90.486-90.485S368.13,0,318.236,0z"/>
	</g>
</g>
<g>
	<g>
		<path d="M211.667,182.766v-29.872c7.093,2.298,12.087,7.697,12.087,13.976c0,4.214,3.415,7.63,7.63,7.63
			c4.215,0,7.63-3.416,7.63-7.63c0-14.493-11.719-26.643-27.346-29.68v-2.224c0-4.214-3.415-7.63-7.63-7.63s-7.63,3.416-7.63,7.63
			v2.224c-15.627,3.037-27.346,15.187-27.346,29.68c0,14.493,11.719,26.643,27.346,29.68v29.873
			c-7.093-2.298-12.087-7.697-12.087-13.977c0-4.214-3.415-7.63-7.63-7.63c-4.215,0-7.63,3.416-7.63,7.63
			c0,14.493,11.719,26.643,27.346,29.681v2.224c0,4.214,3.415,7.63,7.63,7.63s7.63-3.416,7.63-7.63v-2.224
			c15.627-3.037,27.346-15.188,27.346-29.681C239.013,197.953,227.294,185.802,211.667,182.766z M196.407,180.846
			c-7.093-2.298-12.087-7.697-12.087-13.976c0-6.28,4.993-11.679,12.087-13.976V180.846z M211.667,226.423v-27.954
			c7.093,2.298,12.087,7.697,12.087,13.976C223.754,218.725,218.761,224.125,211.667,226.423z"/>
	</g>
</g>
<g>
	<g>
		<path d="M168.034,114.625c-2.167-3.613-6.851-4.786-10.468-2.62c-27.149,16.28-44.016,46.037-44.016,77.655
			c0,19.497,6.106,38.065,17.657,53.699c1.499,2.025,3.806,3.097,6.144,3.097c1.576,0,3.164-0.486,4.528-1.494
			c3.389-2.504,4.106-7.282,1.602-10.67c-9.598-12.989-14.671-28.422-14.671-44.631c0-26.686,13.683-50.824,36.604-64.569
			C169.027,122.925,170.2,118.238,168.034,114.625z"/>
	</g>
</g>
<g>
	<g>
		<path d="M204.038,99.175c-6.388,0-12.77,0.67-18.965,1.992c-4.121,0.88-6.749,4.934-5.87,9.054
			c0.881,4.12,4.936,6.746,9.056,5.869c5.15-1.1,10.459-1.656,15.78-1.656c41.479,0,75.227,33.747,75.227,75.228
			c0,16.209-5.073,31.641-14.671,44.631c-2.503,3.388-1.786,8.166,1.602,10.67c1.364,1.008,2.952,1.494,4.528,1.494
			c2.338,0,4.646-1.071,6.142-3.096c11.551-15.633,17.657-34.202,17.657-53.699C294.524,139.768,253.932,99.175,204.038,99.175z"/>
	</g>
</g>
<g>
	<g>
		<path d="M401.214,190.673H309.07c-4.215,0-7.63,3.416-7.63,7.63s3.415,7.63,7.63,7.63h92.144c2.946,0,5.344,2.397,5.344,5.343
			v9.751H304.321c-4.215,0-7.63,3.416-7.63,7.63c0,4.214,3.415,7.63,7.63,7.63h102.236v5.151c0,4.214,3.415,7.63,7.63,7.63
			s7.63-3.416,7.63-7.63v-30.16C421.816,199.915,412.575,190.673,401.214,190.673z"/>
	</g>
</g>
<g>
	<g>
		<path d="M414.187,324.411c4.215,0,7.63-3.416,7.63-7.63v-38.087c0-14.464-11.767-26.23-26.23-26.23H114.29
			c-12.802,0-23.221-10.395-23.264-23.188v-0.081c0-0.007-0.001-0.014-0.001-0.021c0.01-9.121,5.387-17.439,13.706-21.195
			c3.84-1.733,5.548-6.252,3.815-10.092c-1.735-3.84-6.252-5.549-10.093-3.815c-13.782,6.222-22.687,20.01-22.687,35.128
			c0,0.026,0.002,0.052,0.002,0.078v244.193c0,21.245,17.279,38.529,38.52,38.529h281.298c14.464,0,26.23-11.767,26.23-26.23
			v-49.961c0.001-4.214-3.415-7.63-7.629-7.63c-4.215,0-7.63,3.416-7.63,7.63v49.961c0,6.05-4.922,10.971-10.971,10.971H114.287
			c-12.826,0-23.261-10.438-23.261-23.27V259.88c6.469,4.918,14.53,7.843,23.264,7.843h281.296c6.05,0,10.971,4.922,10.971,10.971
			v38.087C406.557,320.995,409.972,324.411,414.187,324.411z"/>
	</g>
</g>
<g>
	<g>
		<path d="M415.128,330.497h-54.376c-25.253,0-45.798,20.545-45.798,45.798c0,25.253,20.544,45.798,45.798,45.798h13.823
			c4.215,0,7.63-3.416,7.63-7.63c0-4.214-3.415-7.63-7.63-7.63h-13.823c-16.839,0-30.539-13.7-30.539-30.539
			s13.7-30.539,30.539-30.539h54.376c3.225,0,5.848,2.624,5.848,5.849v49.381c0,3.225-2.624,5.848-5.848,5.848h-15.121
			c-4.215,0-7.63,3.416-7.63,7.63s3.415,7.63,7.63,7.63h15.121c11.639,0,21.107-9.469,21.107-21.107v-49.381
			C436.235,339.965,426.767,330.497,415.128,330.497z"/>
	</g>
</g>
<g>
	<g>
		<path d="M359.903,353.398c-12.625,0-22.897,10.272-22.897,22.897c0,12.625,10.271,22.897,22.897,22.897
			s22.898-10.271,22.898-22.897S372.528,353.398,359.903,353.398z M359.902,383.932c-4.212,0-7.638-3.426-7.638-7.638
			c0-4.212,3.426-7.638,7.638-7.638c4.213,0,7.639,3.427,7.639,7.638C367.541,380.506,364.115,383.932,359.902,383.932z"/>
	</g>
</g>
<g>
	<g>
		<path d="M152.748,38.595c-4.215,0-7.63,3.416-7.63,7.63v38.656c0,4.214,3.415,7.63,7.63,7.63s7.63-3.416,7.63-7.63V46.225
			C160.378,42.011,156.963,38.595,152.748,38.595z"/>
	</g>
</g>
<g>
	<g>
		<path d="M190.891,23.335c-4.215,0-7.63,3.416-7.63,7.63v38.656c0,4.214,3.415,7.63,7.63,7.63c4.215,0,7.63-3.416,7.63-7.63V30.965
			C198.52,26.751,195.105,23.335,190.891,23.335z"/>
	</g>
</g>
`]
