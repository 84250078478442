export const icoCruises = ['512 512', `
<title>Cruises</title>
<g>
	<g>
		<circle cx="98.07" cy="346.89" r="7.5"/>
	</g>
</g>
<g>
	<g>
		<circle cx="147.24" cy="346.89" r="7.5"/>
	</g>
</g>
<g>
	<g>
		<circle cx="196.41" cy="346.89" r="7.5"/>
	</g>
</g>
<g>
	<g>
		<circle cx="245.59" cy="346.89" r="7.5"/>
	</g>
</g>
<g>
	<g>
		<circle cx="294.76" cy="346.89" r="7.5"/>
	</g>
</g>
<g>
	<g>
		<circle cx="343.93" cy="346.89" r="7.5"/>
	</g>
</g>
<g>
	<g>
		<circle cx="393.11" cy="346.89" r="7.5"/>
	</g>
</g>
<g>
	<g>
		<circle cx="442.28" cy="346.89" r="7.5"/>
	</g>
</g>
<g>
	<g>
		<g>
			<path d="M504.5,294.5h-9.09V216c0-4.142-3.358-7.5-7.5-7.5h-28.549V90c0-4.142-3.358-7.5-7.5-7.5h-96c-4.142,0-7.5,3.358-7.5,7.5
				v118.5h-13.858V90c0-4.142-3.358-7.5-7.5-7.5h-96c-4.142,0-7.5,3.358-7.5,7.5v118.5h-13.858V90c0-4.142-3.358-7.5-7.5-7.5h-96
				c-4.142,0-7.5,3.358-7.5,7.5v118.5H73.49c-2.729,0-5.242,1.482-6.563,3.87L21.489,294.5H7.5c-4.142,0-7.5,3.358-7.5,7.5v16.076
				c0,4.142,3.358,7.5,7.5,7.5s7.5-3.358,7.5-7.5V309.5c0.008,0,0,0,10.91,0.001h462c0.01,0,0.019-0.001,0.029-0.001H497V376
				c0,21.229-17.271,38.5-38.5,38.5h-23.722c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5H458.5c29.5,0,53.5-24,53.5-53.5
				v-74C512,297.857,508.642,294.5,504.5,294.5z M363.361,97.5h81v26.313h-81V97.5z M363.361,138.813h81v15h-81V138.813z
				 M363.361,168.813h81V208.5h-81V168.813z M238.503,97.5h81v26.313h-81V97.5z M238.503,138.813h81v15h-81V138.813z
				 M238.503,168.813h81V208.5h-81V168.813z M113.645,97.5h81v26.313h-81V97.5z M113.645,138.813h81v15h-81V138.813z
				 M113.645,168.813h81V208.5h-81V168.813z M480.41,294.5H38.631l7.192-12.999H480.41V294.5z M480.41,266.5H54.122l0.664-1.201
				l7.635-13.799H419.5c4.142,0,7.5-3.358,7.5-7.5c0-4.142-3.358-7.5-7.5-7.5H70.719l7.193-13h28.232h96h28.858h96h28.858h96h28.549
				v13H453.5c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h26.91V266.5z"/>
			<path d="M21.489,294.5l13.337-24.107C34.83,270.385,21.485,294.507,21.489,294.5z"/>
		</g>
	</g>
</g>
<g>
	<g>
		<path d="M400.605,414.5H65.5C37.654,414.5,15,391.846,15,364v-12.59c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5V364
			c0,36.117,29.383,65.5,65.5,65.5h335.105c4.142,0,7.5-3.358,7.5-7.5C408.105,417.858,404.748,414.5,400.605,414.5z"/>
	</g>
</g>
`]
