export const icoLogout = ['512 512', `
<title>logout</title>
<g>
	<g>
		<path d="M384,176c0-59.633-37.445-113.789-93.18-134.766c-2.461-0.922-5.211-0.594-7.375,0.906
			c-2.156,1.492-3.445,3.953-3.445,6.578v35.039c0,2.984,1.664,5.727,4.313,7.102C316.195,107.414,336,140.039,336,176
			c0,38.358-22.604,72.72-57.672,88H264v-40c0-6.492-2.531-12.531-6.977-16.852c-3.82-3.945-8.977-6.438-14.492-7.023
			c-6.492-0.68-12.859,1.414-17.883,5.922c-5.5,4.938-8.648,12.039-8.648,19.477v43.439c-41.636-10.778-72-49.178-72-92.963
			c0-35.961,19.805-68.586,51.688-85.141c2.648-1.375,4.313-4.117,4.313-7.102V48.719c0-2.625-1.289-5.086-3.445-6.578
			c-2.164-1.492-4.914-1.82-7.375-0.906C133.445,62.211,96,116.367,96,176c0,54.953,31.589,104.813,80,128.907V336
			c0,1.875,0.656,3.688,1.852,5.125L216,386.903V472c0,4.422,3.578,8,8,8h112c4.422,0,8-3.578,8-8v-88v-96
			c0-3.675-0.891-7.162-2.402-10.318C368.531,250.812,384,214.227,384,176z M112,176c0-49.055,28.492-93.938,72-115.133v18.148
			c-34.664,19.953-56,56.672-56,96.984c0,52.469,37.375,98.336,88,109.438V296h-20.656C145.438,277.406,112,229.359,112,176z
			 M328,464h-96v-72h96V464z M328,376H227.75L192,333.102V312h1.922H224c4.422,0,8-3.578,8-8v-25.203v-53.273
			c0-2.859,1.25-5.695,3.336-7.57c1.148-1.023,3.055-2.164,5.5-1.922c1.813,0.195,3.477,0.992,4.867,2.422
			C247.18,219.891,248,221.867,248,224v48c0,4.422,3.578,8,8,8h24.078H320c2.133,0,4.109,0.82,5.703,2.453
			C327.18,283.891,328,285.867,328,288V376z M330.516,266.414c-0.185-0.091-0.395-0.121-0.582-0.207
			c-1.053-0.483-2.153-0.837-3.271-1.161c-0.513-0.148-1-0.352-1.525-0.466c-1.668-0.362-3.38-0.581-5.137-0.581h-10.633
			C335.922,243.109,352,210.969,352,176c0-40.313-21.336-77.031-56-96.984V60.867c43.508,21.195,72,66.078,72,115.133
			C368,209.945,354.414,242.547,330.516,266.414z"/>
	</g>
</g>
<g>
	<g>
		<path d="M256,0h-32c-4.422,0-8,3.578-8,8v168c0,4.422,3.578,8,8,8h32c4.422,0,8-3.578,8-8V8C264,3.578,260.422,0,256,0z M248,168
			h-16V16h16V168z"/>
	</g>
</g>
`]
